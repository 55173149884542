import tribunales from "../../assets/tribunales.svg";

const TribuanalesFederales = ({
  cardData: {
    showTribunalesFederalesColec,
    altTribunalesFederalesColec,
    showTribunalesFederalesInd,
    altTribunalesFederalesInd,
    TribunalesFederalesIndividual,
    TribunalesFederalesColectiva,
  },
}) => {
  return (
    <div className="row flex-column">
      <div className="col mb-4">
        <div className="row justify-content-center">
          <img
            src={tribunales}
            alt="Tribunales laborales federales"
            className="w-25"
          />
        </div>
      </div>
      <div className="col">
        {showTribunalesFederalesInd && TribunalesFederalesIndividual ? (
          <>
            <h6 className="brief text-center">Asuntos individuales</h6>
            <div className="row justify-content-center">
              <div className="col">
                <div className="row flex-column justify-content-between align-items-center">
                  <div>
                    <h3
                      className="text-center brief title-card"
                      style={{ fontWeight: "900" }}
                    >
                      {TribunalesFederalesIndividual.total_tramite}
                    </h3>
                  </div>
                  <div className="text-center" style={{ fontSize: "smaller" }}>
                    Demandas presentadas
                  </div>
                  <br></br>
                  <div>
                    <h3
                      className="text-center brief title-card"
                      style={{ fontWeight: "900" }}
                    >
                      {TribunalesFederalesIndividual.tasa_resolucion}%
                    </h3>
                  </div>
                  <div className="text-center" style={{ fontSize: "smaller" }}>
                    Tasa de resolución
                  </div>
                </div>
              </div>
              
              <div className="col">
                <div className="row flex-column justify-content-around align-items-center">
                <div>
                    <h3
                      className="text-center brief title-card"
                      style={{ fontWeight: "900" }}
                    >
                      {TribunalesFederalesIndividual.total_conciliados}
                    </h3>
                  </div>
                  <div className="text-center" style={{ fontSize: "smaller" }}>
                    Sentencias emitidas
                  </div>
                  <br></br>
                  <div>
                    <h3
                      className="text-center brief title-card"
                      style={{ fontWeight: "900" }}
                    >
                      {TribunalesFederalesIndividual.fuera_sentencia}
                    </h3>
                  </div>
                  <div className="text-center" style={{ fontSize: "smaller" }}>
                    Asuntos resueltos fuera de sentencia
                  </div>
                </div>
              </div>
              {/*    <div className='col-md-5'>
            <div className='row flex-column justify-content-around align-items-center'>
              <div>
               <h3 className='text-center brief title-card' style={{ fontWeight: '900' }}>70 <span style={{ fontSize: 'small' }}>%</span></h3>
              </div>
              <div className='text-center' style={{ fontSize: 'smaller' }}>
                Porcentaje de conciliación
              </div>
            </div>
          </div> */}
            </div>
            <div className="row">
              <div class="col">
                {/* Espacio vacío */}
                <br></br>
                <div>
                  <h3
                    className="text-center brief title-card"
                    style={{ fontWeight: "900" }}
                  >
                    {TribunalesFederalesIndividual.total_jueces}
                  </h3>
                </div>
                <div className="text-center" style={{ fontSize: "smaller" }}>
                  Numero total de jueces
                </div>
              </div>
              <div class="col">
                {/* Contenido para colocar debajo del espacio vacío */}
                <br></br>
                <div>
                  <h3
                    className="text-center brief title-card"
                    style={{ fontWeight: "900" }}
                  >
                    {TribunalesFederalesIndividual.hombres}
                  </h3>
                </div>
                <div className="text-center" style={{ fontSize: "smaller" }}>
                  Hombres
                </div>
              </div>
              <div class="col">
                {/* Otro contenido para colocar debajo del espacio vacío */}
                <br></br>
                <div>
                  <h3
                    className="text-center brief title-card"
                    style={{ fontWeight: "900" }}
                  >
                    {TribunalesFederalesIndividual.mujeres}
                  </h3>
                </div>
                <div className="text-center" style={{ fontSize: "smaller" }}>
                  Mujeres
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="row flex-column">
            <div className="col">
            </div>
          </div>
        )}
        {showTribunalesFederalesColec && TribunalesFederalesColectiva ? (
          <>
            <h6 className="brief text-center mt-3">Asuntos colectivos</h6>
            <div className="row justify-content-center">
              <div className="col-md-5">
                <div className="row flex-column justify-content-between align-items-center">
                  <div>
                    <h3
                      className="text-center brief title-card"
                      style={{ fontWeight: "900" }}
                    >
                      {TribunalesFederalesColectiva.total_tramite}
                    </h3>
                  </div>
                  <div className="text-center" style={{ fontSize: "smaller" }}>
                    Demandas presentadas
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="row flex-column justify-content-around align-items-center">
                  <div>
                    <h3
                      className="text-center brief title-card"
                      style={{ fontWeight: "900" }}
                    >
                      {TribunalesFederalesColectiva.total_conciliados}
                    </h3>
                  </div>
                  <div className="text-center" style={{ fontSize: "smaller" }}>
                    Sentencias emitidas
                  </div>
                </div>
              </div>
              {/* <div className='col-md-5'>
              <div className='row flex-column justify-content-around align-items-center'>
                <div>
                 <h3 className='text-center brief title-card' style={{ fontWeight: '900' }}>70 <span style={{ fontSize: 'small' }}>%</span></h3>
                </div>
                <div className='text-center' style={{ fontSize: 'smaller' }}>
                  Porcentaje de conciliación
                </div>
              </div>
            </div> */}
            </div>
          </>
        ) : (
          <div className="row flex-column">
            <div className="col">
              
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TribuanalesFederales;
