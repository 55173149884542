import { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { toast } from 'react-toastify';
import Loading from '../Loading';
import { Navigate } from 'react-router-dom';

const Usuarios = () => {
  const { user, getUsers, deleteUser, createUser, generatePassword, loading } = useAuth();
  const [users, setUsers] = useState([]);
  const [{ name, email }, setFormState] = useState({
    name: '',
    email: ''
  });

  useEffect(() => {
    const getUserList = async () => {
      const users = await getUsers();
      setUsers(users);
    };
    user?.role === 'admin' && getUserList();
    // eslint-disable-next-line
  }, [user]);

  const handleInputChange = e => setFormState(prev => ({ ...prev, [e.target.id]: e.target.value }));

  const handleCreate = async e => {
    e.preventDefault();
    if (!name || !email) return toast.error('Nombre y Email son campos requeridos');
    const user = await createUser({ name, email });
    if (!user)
      return setFormState({
        name: '',
        email: ''
      });
    setUsers(prev => [...prev, user]);
    setFormState({
      name: '',
      email: ''
    });
  };

  const handleGeneratePassword = async id => await generatePassword(id);

  const handleDelete = async id => {
    await deleteUser(id);
    setUsers(prev => prev.filter(user => user.id !== id));
  };

  if (user.role !== 'admin') return <Navigate to='/admin' />;
  if (loading) return <Loading />;
  return (
    <div className='container'>
      <button type='button' className='btn btn-primary' data-bs-toggle='modal' data-bs-target='#exampleModal'>
        Crear usuario
      </button>
      <table className='table table-striped table-hover'>
        <thead>
          <tr>
            <th scope='col'>#</th>
            <th scope='col'>Nombre</th>
            <th scope='col'>Email</th>
            <th scope='col'>Rol</th>
            <th scope='col'>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.id}>
              <th scope='row'>{user.id}</th>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.role}</td>
              <td>
                <button className='btn btn-info' onClick={() => handleGeneratePassword(user.id)}>
                  Generar contraseña
                </button>
                <button className='btn btn-warning' onClick={() => handleDelete(user.id)}>
                  Borrar
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className='modal fade' id='exampleModal' tabIndex='-1' aria-labelledby='exampleModalLabel' aria-hidden='true'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                Crear usuario
              </h5>
              <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
            </div>
            <form onSubmit={handleCreate}>
              <div className='modal-body'>
                <div className='mb-3'>
                  <label htmlFor='name' className='form-label'>
                    Nombre
                  </label>
                  <input type='text' className='form-control' id='name' value={name} onChange={handleInputChange} />
                </div>
                <div className='mb-3'>
                  <label htmlFor='email' className='form-label'>
                    Email
                  </label>
                  <input type='email' className='form-control' id='email' value={email} onChange={handleInputChange} />
                </div>
              </div>
              <div className='modal-footer'>
                <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                  Close
                </button>
                <button type='submit' className='btn btn-primary' data-bs-dismiss='modal'>
                  Crear
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Usuarios;
