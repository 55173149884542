import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import NavBar from './Admin/NavBar';
import 'react-toastify/dist/ReactToastify.css';

const Layout = () => {
  return (
    <>
      <ToastContainer />
      <NavBar />
      <div className='dashboard container-fluid pt-5 pb-5' style={{ minHeight: '1000px' }}>
        <Outlet />
      </div>
    </>
  );
};

export default Layout;
