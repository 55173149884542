import contract from '../../assets/contract.svg';

const ModificacionEstatutos = ({
  cardData: {
    Modificacion: { modificados, organizaciones }
  }
}) => {
  return (
    <div className='row flex-column'>
      <div className='col mb-4'>
        <div className='row justify-content-center'>
          <img src={contract} alt='Modificación de Estatutos' className='w-25' />
        </div>
      </div>
      <div className='col'>
        <div className='row'>
          <div className='col-md-4 mb-2'>
            <div className='row flex-column justify-content-around align-items-center'>
              <div>
                <h3 className='text-center brief title-card' style={{ fontWeight: '900' }}>
                  {organizaciones}
                </h3>
              </div>
              <div className='text-center' style={{ fontSize: 'smaller' }}>
                Sindicatos activos
              </div>
            </div>
          </div>
          <div className='col-md-4 mb-2'>
            <div className='row flex-column justify-content-between align-items-center'>
              <div>
                <h3 className='text-center brief title-card' style={{ fontWeight: '900' }}>
                  {modificados}
                </h3>
              </div>
              <div className='text-center text-nowrap' style={{ fontSize: 'smaller' }}>
                Estatutos modificados
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='row flex-column justify-content-around align-items-center'>
              <div>
                <h3 className='text-center brief title-card' style={{ fontWeight: '900' }}>
                  {parseFloat((modificados / organizaciones) * 100).toFixed(2)}{' '}
                  <span style={{ fontSize: 'small' }}>%</span>
                </h3>
              </div>
              <div className='text-center' style={{ fontSize: 'smaller' }}>
                Avance
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModificacionEstatutos;
