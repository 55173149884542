import EditarTribLocIndividual from './EditarTribLocIndividual';
import EditarTribLocColectiva from './EditarTribLocColectiva';

const EditarTribLoc = ({ datos }) => {
  const { individual, colectiva } = datos;
  return (
    <div>
      <EditarTribLocIndividual datos={individual} />
      <EditarTribLocColectiva datos={colectiva} />
    </div>
  );
};

export default EditarTribLoc;
