import { useEffect } from 'react';
import { useField, useFormikContext } from 'formik';

const AutoField = props => {
  const [base, index, fieldName] = props.name.split('.');
  const { values, dirty, setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  const { total_conciliados, total_no_conciliados } = index ? values[base][index] : values;

  useEffect(() => {
    if (!dirty) return;
    if (base === 'admitidas' || fieldName === 'admitidas') {
      return setFieldValue(props.name, total_conciliados + total_no_conciliados);
    }
    if (base === 'porcentaje_conciliacion' || fieldName === 'porcentaje_conciliacion')
      return setFieldValue(
        props.name,
        ((total_conciliados / (total_conciliados + total_no_conciliados)) * 100 || 0).toFixed(1)
      );
  }, [total_conciliados, total_no_conciliados, dirty, setFieldValue, base, fieldName, props.name]);

  return (
    <>
      <input {...props} {...field} disabled />
      {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
    </>
  );
};

export default AutoField;
