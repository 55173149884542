import { Formik, Form, FieldArray, Field } from 'formik';
import { useStatesData } from '../../context/StatesContext';

const Sedes = () => {
  const {
    currentState: { Municipios, Sedes },
    updateSedes,
    deleteSede
  } = useStatesData();

  const initialValues = {
    sedes: Sedes
  };

  const template = {
    name: '',
    tipo: '',
    domicilio: '',
    tel: '',
    MunicipioId: 0
  };

  const handleSubmit = async ({ sedes }, actions) => await updateSedes(sedes, actions);

  const handleDelete = async (oficina, callback) => {
    await deleteSede(oficina);
    callback();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values }) => (
        <Form>
          <FieldArray name='sedes'>
            {({ push, remove }) => (
              <div>
                <h4 className='text-center'>Sedes</h4>
                <div className='row mt-5 justify-content-center'>
                  <button type='button' className='col-md-2 btn btn-info' onClick={() => push(template)}>
                    Agregar sede
                  </button>
                </div>
                {values.sedes.map((sede, index) => (
                  <div className='row align-items-center mt-3' key={index}>
                    <div className='col-md-2'>
                      <label htmlFor={`sedes.${index}.name`} className='form-label'>
                        Nombre:
                      </label>
                      <Field name={`sedes.${index}.name`} className='form-control' />
                    </div>
                    <div className='col-md-2'>
                      <label htmlFor={`sedes.${index}.MunicipioId`} className='form-label'>
                        Ubicación:
                      </label>
                      <Field name={`sedes.${index}.MunicipioId`} className='form-select' as='select'>
                        {Municipios.map(m => (
                          <option key={m.id} value={m.id}>
                            {m.name}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <div className='col-md-2'>
                      <label htmlFor={`sedes.${index}.tipo`} className='form-label'>
                        Tipo:
                      </label>
                      <Field name={`sedes.${index}.tipo`} className='form-select' as='select'>
                        <option key='cfcrl' value='cfcrl'>
                          Oficina del CFCRL
                        </option>
                        <option key='conciliacion' value='conciliacion'>
                          Centro de Conciliación Local
                        </option>
                        <option key='tribfed' value='tribfed'>
                          Tribunal Federal
                        </option>
                        <option key='tribloc' value='tribloc'>
                          Tribunal Laboral
                        </option>
                      </Field>
                    </div>
                    <div className='col-md-3'>
                      <label htmlFor={`sedes.${index}.domicilio`} className='form-label'>
                        Domicilio
                      </label>
                      <Field name={`sedes.${index}.domicilio`} className='form-control' />
                    </div>
                    <div className='col-md-2'>
                      <label htmlFor={`sedes.${index}.tel`} className='form-label'>
                        Tel:
                      </label>
                      <Field name={`sedes.${index}.tel`} className='form-control' />
                    </div>
                    <div className='col-md-1'>
                      <button
                        type='button'
                        className='btn btn-danger'
                        onClick={() => handleDelete(sede.id, () => remove(index))}
                      >
                        &times;
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </FieldArray>
          {!!values.sedes.length && (
            <div className='row mt-5 justify-content-center'>
              <button className='col-md-4 btn btn-success btn-block' type='submit'>
                Guardar
              </button>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default Sedes;
