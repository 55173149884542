import { Formik, Form, Field } from 'formik';
import { useStatesData } from '../../context/StatesContext';
import AutoField from './AutoField';

const EditarCFCRLColectiva = ({ datos }) => {
  const { updateCFCRLColectiva } = useStatesData();
  const initialValues = datos;

  const handleSubmit = async values => updateCFCRLColectiva(values);

  return (
    <>
      <h4 className='text-center mt-5'>Materia Colectiva</h4>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <div className='row  justify-content-center align-items-center mt-3'>
            <div className='col-md-3'>
              <label htmlFor='admitidas' className='form-label'>
                Admitidas:
              </label>
              <Field name='admitidas' className='form-control' type='number' />
            </div>
            <div className='col-md-2'>
              <label htmlFor='total_conciliados' className='form-label'>
                Total conciliados:
              </label>
              <Field name='total_conciliados' className='form-control' type='number' />
            </div>
            <div className='col-md-2'>
              <label htmlFor='total_no_conciliados' className='form-label'>
                Total no conciliados:
              </label>
              <Field name='total_no_conciliados' className='form-control' type='number' />
            </div>
            <div className='col-md-2'>
              <label htmlFor='total_tramite' className='form-label'>
                Total trámite:
              </label>
              <Field name='total_tramite' className='form-control' type='number' />
            </div>
            <div className='col-md-2'>
              <label htmlFor='porcentaje_conciliacion' className='form-label'>
                Porcentaje conciliados:
              </label>
              <AutoField name='porcentaje_conciliacion' className='form-control' type='number' />
            </div>
            <div className='row mt-5 justify-content-center'>
              <button className='col-md-4 btn btn-success btn-block' type='submit'>
                Guardar
              </button>
            </div>
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default EditarCFCRLColectiva;
