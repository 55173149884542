import SemVer from '../../assets/s_verde.svg';
import SemNar from '../../assets/s_nar.svg';
import SemAma from '../../assets/s_amar.svg';
import SemRoj from '../../assets/s_roj.svg';

const ArmonizacionLegislativa = ({ cardData: { Armonizacions } }) => {
  return (
    <div className='row flex-column position-relative h-75'>
      <div className='col'>
        <div className='row h-75 align-items-center'>
          {Armonizacions.map(el => (
            <div className='col-md-4 h-25' key={el.type} data-toggle='tooltip' data-placement='top' title={el.caption}>
              <div className='row flex-column justify-content-between align-items-center'>
                <img
                  src={el.status === 4 ? SemVer : el.status === 3 ? SemAma : el.status === 2 ? SemNar : SemRoj}
                  alt={
                    el.status === 4
                      ? 'Aprobada y publicada'
                      : el.status === 3
                      ? 'Presentada ante el congreso'
                      : el.status === 2
                      ? 'En revisón'
                      : 'No hay iniciativa de reforma'
                  }
                  style={{ width: '80px' }}
                />
                <div className='text-center' style={{ fontSize: 'smaller' }}>
                  {el.url ? (
                    <a
                      href={el.url}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-decoration-none liga-armonizacion'
                    >
                      {el.title}
                    </a>
                  ) : (
                    el.title
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='col position-absolute' style={{ bottom: 15 }}>
        <div className='progress'>
          <div
            className='progress-bar bg-verde'
            role='progressbar'
            aria-valuenow='25'
            style={{ width: '25%' }}
            aria-valuemin='0'
            aria-valuemax='100'
          ></div>
          <div
            className='progress-bar bg-amarillo'
            role='progressbar'
            aria-valuenow='25'
            style={{ width: '25%' }}
            aria-valuemin='0'
            aria-valuemax='100'
          ></div>
          <div
            className='progress-bar bg-naranja'
            role='progressbar'
            aria-valuenow='25'
            style={{ width: '25%' }}
            aria-valuemin='0'
            aria-valuemax='100'
          ></div>
          <div
            className='progress-bar bg-rojo'
            role='progressbar'
            aria-valuenow='25'
            style={{ width: '25%' }}
            aria-valuemin='0'
            aria-valuemax='100'
          ></div>
        </div>
        <div className='row flex-nowrap '>
          <div className='col text-center' style={{ fontSize: 'xx-small' }}>
            Aprobada y publicada
          </div>
          <div className='col text-center' style={{ fontSize: 'xx-small' }}>
            Presentada ante el congreso
          </div>
          <div className='col text-center' style={{ fontSize: 'xx-small' }}>
            En revisión
          </div>
          <div className='col text-center' style={{ fontSize: 'xx-small' }}>
            No hay iniciativa de reforma
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArmonizacionLegislativa;
