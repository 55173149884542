import subsidios from '../../assets/money.svg';

const Subsidios = ({ cardData: { showSubsidios, altSubsidios, subsidiosTLL, subsidiosCCL, Subsidios } }) => {
  
  // Función para dar formato de moneda mexicana
  const formatCurrency = (value) => {
    return parseFloat(value).toLocaleString('es-MX', { style: 'currency', currency: 'MXN' });
  };

  return showSubsidios && Subsidios ? (
    <div className='row flex-column'>
      <div className='col'>
        <div className='row justify-content-center mb-5'>
          <img src={subsidios} alt='Subsidios' className='w-25' />
        </div>
        <div className='row'>
          <div className='col'>
            <h6 className='brief text-center'>
              Subsidios para la implementación de la reforma al sistema de justicia laboral.
            </h6>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className='row flex-column'>
      <div className='col mb-4'>
        <div className='row justify-content-center'>
          <img src={subsidios} alt='Subsidios' className='w-25' />
        </div>
      </div>
      <div className='col'>
        {altSubsidios !== '0' && (
          <h6 className='brief text-center'>{altSubsidios}</h6>
        )}
        <br></br>
        {(subsidiosTLL !== '0' || subsidiosCCL !== '0') && (
          <table className="table table-bordered text-white">
            <thead>
              <tr>
                <th scope="col">Año</th>
                <th scope="col">Autoridad Solicitante</th>
                <th scope="col">Total</th>
              </tr>
            </thead>
            <tbody>
              {subsidiosTLL !== '0' && (
                <tr>
                  <td>2023</td>
                  <td>Tribunales Laborales</td>
                  <td className="text-end">{formatCurrency(subsidiosTLL)}</td>
                </tr>
              )}
              {subsidiosCCL !== '0' && (
                <tr>
                  <td>2023</td>
                  <td>Centro de Conciliación</td>
                  <td className="text-end">{formatCurrency(subsidiosCCL)}</td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );  
};

export default Subsidios;
