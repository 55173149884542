import { Formik, Form, FieldArray, Field } from 'formik';
import { useStatesData } from '../../context/StatesContext';
import AutoField from './AutoField';

const EditarCFCRLIndividual = ({ datos }) => {
  const { updateCFCRLIndividual, deleteCFCRLIndividual } = useStatesData();
  const initialValues = {
    individual: datos
  };

  const template = {
    sede: '',
    admitidas: 0,
    total_conciliados: 0,
    total_no_conciliados: 0,
    total_tramite: 0,
    porcentaje_conciliacion: 0,
    hombres: 0,
    mujeres: 0,
  };

  const handleSubmit = async ({ individual }, actions) => await updateCFCRLIndividual(individual, actions);

  const handleDelete = async (oficina, callback) => {
    await deleteCFCRLIndividual(oficina);
    callback();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values }) => (
        <Form>
          <FieldArray name='individual'>
            {({ push, remove }) => (
              <div>
                <h4 className='text-center'>Materia Individual</h4>
                <div className='row mt-5 justify-content-center'>
                  <button type='button' className='col-md-2 btn btn-info' onClick={() => push(template)}>
                    Agregar sede
                  </button>
                </div>
                {values.individual.map((oficina, index) => (
                  <div className='row align-items-center mt-3' key={index}>
                    <div className='col-md'>
                      <label htmlFor={`individual.${index}.sede`} className='form-label'>
                        Sede:
                      </label>
                      <Field name={`individual.${index}.sede`} className='form-control' />
                    </div>
                    <div className='col-md'>
                      <label htmlFor={`individual.${index}.admitidas`} className='form-label'>
                        Admitidas:
                      </label>
                      <Field name={`individual.${index}.admitidas`} className='form-control' type='number' />
                    </div>
                    <div className='col-md'>
                      <label htmlFor={`individual.${index}.total_conciliados`} className='form-label'>
                        Total conciliados:
                      </label>
                      <Field name={`individual.${index}.total_conciliados`} className='form-control' type='number' />
                    </div>
                    <div className='col-md'>
                      <label htmlFor={`individual.${index}.total_no_conciliados`} className='form-label'>
                        Total no conciliados:
                      </label>
                      <Field name={`individual.${index}.total_no_conciliados`} className='form-control' type='number' />
                    </div>
                    <div className='col-md'>
                      <label htmlFor={`individual.${index}.total_tramite`} className='form-label'>
                        Total trámite:
                      </label>
                      <Field name={`individual.${index}.total_tramite`} className='form-control' type='number' />
                    </div>
                    <div className='col-md'>
                      <label htmlFor={`individual.${index}.total_conciliadores`} className='form-label'>
                        Total de conciliadores:
                      </label>
                      <Field name={`individual.${index}.total_conciliadores`} className='form-control' type='number' />
                    </div>
                    <div className='col-md'>
                      <label htmlFor={`individual.${index}.hombres`} className='form-label'>
                        Número de Hombres:
                      </label>
                      <Field name={`individual.${index}.hombres`} className='form-control' type='number' />
                    </div>
                    <div className='col-md'>
                      <label htmlFor={`individual.${index}.mujeres`} className='form-label'>
                        Número de Mujeres:
                      </label>
                      <Field name={`individual.${index}.mujeres`} className='form-control' type='number' />
                    </div>
                    <div className='col-md'>
                      <label htmlFor={`individual.${index}.porcentaje_conciliacion`} className='form-label'>
                        Porcentaje de conciliación:
                      </label>
                      <AutoField
                        name={`individual.${index}.porcentaje_conciliacion`}
                        disabled
                        className='form-control'
                        type='number'
                      />
                    </div>
                    <div className='col-md'>
                      <button
                        type='button'
                        className='btn btn-danger'
                        onClick={() => handleDelete(oficina.id, () => remove(index))}
                      >
                        &times;
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </FieldArray>
          {!!values.individual.length && (
            <div className='row mt-5 justify-content-center'>
              <button className='col-md-4 btn btn-success btn-block' type='submit'>
                Guardar
              </button>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default EditarCFCRLIndividual;
