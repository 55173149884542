import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useStatesData } from '../../context/StatesContext';

const StatesList = () => {
  const { states } = useStatesData();
  const [filter, setFilter] = useState('');

  const filterStates = state => {
    if (filter) {
      const stateName = state.name.toLowerCase();
      return stateName.includes(filter.toLowerCase()) && state;
    }
    return state;
  };

  return (
    <>
      <div className='row justify-content-center'>
        <div className='col-md-4 text-center'>
          <div className='input-group mb-3'>
            <input
              type='text'
              className='form-control'
              placeholder='Filtrar'
              value={filter}
              onChange={e => setFilter(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className='row justify-content-center'>
        {states.filter(filterStates).map(state => (
          <div key={state.id} className='col-md-6 text-center'>
            <Link to={`/admin/estados/${state.id}`} className='text-decoration-none text-dark'>
              <h3>{state.name}</h3>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
};

export default StatesList;
