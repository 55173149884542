import oficina from '../../assets/oficina.svg';

const OficinasCFRL = ({
  cardData: { showCFCRLColec, altCFCRLColec, showCFCRLInd, altCFCRLInd, CFCRLIndividuals, CFCRLColectiva }
}) => {
  return (
    <div className='row flex-column'>
      <div className='col mb-3'>
        <div className='row justify-content-center'>
          <img src={oficina} alt='Oficinas de la CFRL' className='w-25' />
        </div>
      </div>
      <div className='col'>
        {showCFCRLInd && CFCRLIndividuals ? (
          <>
            <h6 className='brief text-center'>Asuntos individuales</h6>
            {CFCRLIndividuals.map(
              ({ admitidas, total_conciliados, total_no_conciliados, total_tramite, porcentaje_conciliacion,total_conciliadores,hombres,mujeres, sede }, i) => (
                <div>
                  <div>
                    <div className='row' key={i}>
                      <div class="col">
                        {/* Contenido de la primera columna */}
                        <div>
                          <h3 className='text-center brief title-card' style={{ fontWeight: '900' }}>
                            {admitidas}
                          </h3>
                        </div>
                        <div className='text-center' style={{ fontSize: 'smaller' }}>
                          Solicitudes admitidas
                        </div>
                      </div>
                      <div class="col">
                        {/* Contenido de la segunda columna */}
                        <div>
                          <h3 className='text-center brief title-card' style={{ fontWeight: '900' }}>
                            {total_tramite}
                          </h3>
                        </div>
                        <div className='text-center' style={{ fontSize: 'smaller' }}>
                          Asuntos en trámite
                        </div>
                      </div>
                      <div class="col">
                        {/* Contenido de la tercera columna */}
                        <div>
                          <h3 className='text-center brief title-card' style={{ fontWeight: '900' }}>
                            {total_conciliados}
                          </h3>
                        </div>
                        <div className='text-center' style={{ fontSize: 'smaller' }}>
                          Asuntos conciliados
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div class="col">
                        {/* Espacio vacío */}
                        <br></br>
                        <div>
                          <h3 className='text-center brief title-card' style={{ fontWeight: '900' }}>
                            {total_no_conciliados}
                          </h3>
                        </div>
                        <div className='text-center' style={{ fontSize: 'smaller' }}>
                          Asuntos no conciliados
                        </div>
                      </div>
                      <div class="col">
                        {/* Contenido para colocar debajo del espacio vacío */}

                      </div>
                      <div class="col">
                        {/* Otro contenido para colocar debajo del espacio vacío */}
                        <br></br>
                        <div>
                          <h3 className='text-center brief title-card' style={{ fontWeight: '900' }}>
                            {porcentaje_conciliacion}%
                          </h3>
                        </div>
                        <div className='text-center' style={{ fontSize: 'smaller' }}>
                          Porcentaje de conciliación
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div class="col">
                        {/* Espacio vacío */}
                        <br></br>
                        <div>
                          <h3 className='text-center brief title-card' style={{ fontWeight: '900' }}>
                            {total_conciliadores}
                            <span style={{ fontSize: 'small' }}></span>
                          </h3>
                        </div>
                        <div className='text-center' style={{ fontSize: 'smaller' }}>
                          Numero total de conciliadores
                        </div>
                      </div>
                      <div class="col">
                        {/* Contenido para colocar debajo del espacio vacío */}
                        <br></br>

                        <div>
                          <h3 className='text-center brief title-card' style={{ fontWeight: '900' }}>
                            {hombres}
                            <span style={{ fontSize: 'small' }}></span>
                          </h3>
                        </div>
                        <div className='text-center' style={{ fontSize: 'smaller' }}>
                          Hombres
                        </div>
                      </div>
                      <div class="col">
                        {/* Otro contenido para colocar debajo del espacio vacío */}
                        <br></br>

                        <div>
                            <h3 className='text-center brief title-card' style={{ fontWeight: '900' }}>
                              {mujeres}
                              <span style={{ fontSize: 'small' }}></span>
                            </h3>
                          </div>
                          <div className='text-center' style={{ fontSize: 'smaller' }}> 
                            Mujeres
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </>
        ) : (
          <div className='row flex-column'>
            <div className='col'>
            </div>
          </div>
        )}
{showCFCRLColec && CFCRLColectiva && (CFCRLColectiva.admitidas !== 0 || CFCRLColectiva.total_tramite !== 0 || CFCRLColectiva.total_conciliados !== 0 ) ? (
  <>
    <h6 className='brief text-center mt-3'>Asuntos colectivos</h6>
    <div className='row'>
      <div className='col'>
        <div className='row'>
          <div>
            <h3 className='text-center brief title-card' style={{ fontWeight: '900' }}>
              {CFCRLColectiva.admitidas}
            </h3>
          </div>
          <div className='text-center' style={{ fontSize: 'smaller' }}>
            Solicitudes admitidas
          </div>
        </div>
      </div>
      <div className='col'>
        <div className='row'>
          <div>
            <h3 className='text-center brief title-card' style={{ fontWeight: '900' }}>
              {CFCRLColectiva.total_tramite}
            </h3>
          </div>
          <div className='text-center' style={{ fontSize: 'smaller' }}>
            Asuntos en trámite
          </div>
        </div>
      </div>
      <div className='col'>
        <div className='row flex-column justify-content-around align-items-center'>
          <div>
            <h3 className='text-center brief title-card' style={{ fontWeight: '900' }}>
              {CFCRLColectiva.total_conciliados}
            </h3>
          </div>
          <div className='text-center' style={{ fontSize: 'smaller' }}>
            Asuntos conciliados
          </div>
        </div>
      </div>
      <div className='col'>
        <div className='row flex-column justify-content-around align-items-center'>
          <div>
            <h3 className='text-center brief title-card' style={{ fontWeight: '900' }}>
              {CFCRLColectiva.porcentaje_conciliacion} <span style={{ fontSize: 'small' }}>%</span>
            </h3>
          </div>
          <div className='text-center' style={{ fontSize: 'smaller' }}>
            Porcentaje de conciliación
          </div>
        </div>
      </div>
    </div>
  </>
) : (
  <div className='row flex-column'>
    <div className='col'>
    </div>
  </div>
)}

      </div>
    </div>
  );
};

export default OficinasCFRL;