import { Formik, Form, FieldArray, Field } from 'formik';
import { useStatesData } from '../../context/StatesContext';

const EditarArmonizacion = ({ datos }) => {
  const { updateArmonizacion } = useStatesData();
  const initialValues = {
    reformas: datos
  };

  const handleSubmit = async ({ reformas }) => updateArmonizacion(reformas);

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values }) => (
        <Form>
          <FieldArray name='reformas'>
            {() => (
              <div>
                {values.reformas.map((reforma, index) => (
                  <div className='row align-items-center mt-3' key={index}>
                    <h4>{reforma.title}</h4>
                    <div className='col-md-2'>
                      <label htmlFor={`reformas.${index}.status`} className='form-label'>
                        Status:
                      </label>
                      <Field name={`reformas.${index}.status`} className='form-select' as='select'>
                        <option value='1'>Rojo</option>
                        <option value='2'>Naranja</option>
                        <option value='3'>Amarillo</option>
                        <option value='4'>Verde</option>
                      </Field>
                    </div>
                    <div className='col-md-5'>
                      <label htmlFor={`reformas.${index}.caption`}>Leyenda: </label>
                      <Field name={`reformas.${index}.caption`} className='form-control' placeholder='Leyenda' />
                    </div>
                    <div className='col-md-5'>
                      <label htmlFor={`reformas.${index}.url`}>Publicación: </label>
                      <Field
                        name={`reformas.${index}.url`}
                        className='form-control'
                        placeholder='Liga a publicación'
                        value={reforma.url ?? ''}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </FieldArray>
          <div className='row mt-5 justify-content-center'>
            <button className='col-md-4 btn btn-success btn-block' type='submit'>
              Guardar
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditarArmonizacion;
