import { Formik, Form, Field } from 'formik';
import { useStatesData } from '../../context/StatesContext';

const DatosGenerales = () => {
  const { currentState, updateState } = useStatesData();
  const {
    etapa,
    showArmonizacion,
    altTextArmonizacion,
    showModificacion,
    altModificacion,
    showCFCRLColec,
    altCFCRLColec,
    showCFCRLInd,
    altCFCRLInd,
    showConciliacionColec,
    altConciliacionColec,
    showConciliacionInd,
    altConciliacionInd,
    showTribunalesFederalesColec,
    altTribunalesFederalesColec,
    showTribunalesFederalesInd,
    altTribunalesFederalesInd,
    showTribunalesLocalesColec,
    altTribunalesLocalesColec,
    showTribunalesLocalesInd,
    altTribunalesLocalesInd,
    showSubsidios,
    altSubsidios,
    subsidiosTLL,
    subsidiosCCL
  } = currentState;
  const initialValues = {
    etapa,
    showArmonizacion,
    altTextArmonizacion,
    showModificacion,
    altModificacion,
    showCFCRLColec,
    altCFCRLColec,
    showCFCRLInd,
    altCFCRLInd,
    showConciliacionColec,
    altConciliacionColec,
    showConciliacionInd,
    altConciliacionInd,
    showTribunalesFederalesColec,
    altTribunalesFederalesColec,
    showTribunalesFederalesInd,
    altTribunalesFederalesInd,
    showTribunalesLocalesColec,
    altTribunalesLocalesColec,
    showTribunalesLocalesInd,
    altTribunalesLocalesInd,
    showSubsidios,
    altSubsidios,
    subsidiosTLL,
    subsidiosCCL
  };

  const handleSubmit = values => {
    for (let prop in values) {
      values[prop] === null && delete values[prop];
    }
    updateState(values);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({
        values: {
          altTextArmonizacion,
          altModificacion,
          altCFCRLColec,
          altCFCRLInd,
          altConciliacionColec,
          altConciliacionInd,
          altTribunalesFederalesColec,
          altTribunalesFederalesInd,
          altTribunalesLocalesColec,
          altTribunalesLocalesInd,
          altSubsidios,
          subsidiosTLL,
          subsidiosCCL
        }
      }) => (
        <Form>
          <div className='row mb-3 justify-content-center'>
            <div className='col-md-6'>
              <label htmlFor='etapa' className='form-label'>
                Etapa:
              </label>
              <Field name='etapa' className='form-select' as='select'>
                <option value='1'>Primera</option>
                <option value='2'>Segunda</option>
                <option value='3'>Tercera</option>
              </Field>
            </div>
          </div>
          <div className='row justify-content-center align-items-center'>
            <div className='col-md-4'>
              <div className='form-check form-switch'>
                <Field className='form-check-input' type='checkbox' name='showArmonizacion' />
                <label className='form-check-label' htmlFor='showArmonizacion'>
                  Mostrar rubro: Armonización Legislativa
                </label>
              </div>
            </div>
            <div className='col-md-8 mb-3'>
              <label htmlFor='altTextArmonizacion' className='form-label'>
                Leyenda alternativa: Armonización Legislativa
              </label>
              <Field name='altTextArmonizacion' value={altTextArmonizacion || ''} className='form-control' />
            </div>
            <div className='col-md-4'>
              <div className='form-check form-switch'>
                <Field className='form-check-input' type='checkbox' name='showModificacion' />
                <label className='form-check-label' htmlFor='showModificacion'>
                  Mostrar rubro: Modificación de Estatutos
                </label>
              </div>
            </div>
            <div className='col-md-8 mb-3'>
              <label htmlFor='altModificacion' className='form-label'>
                Leyenda alternativa: Modificación de Estatutos
              </label>
              <Field name='altModificacion' value={altModificacion || ''} className='form-control' />
            </div>
            <div className='col-md-4'>
              <div className='form-check form-switch'>
                <Field className='form-check-input' type='checkbox' name='showCFCRLColec' />
                <label className='form-check-label' htmlFor='showCFCRLColec'>
                  Mostrar rubro: Oficinas del CFCRL (colectiva)
                </label>
              </div>
            </div>
            <div className='col-md-8 mb-3'>
              <label htmlFor='altCFCRLColec' className='form-label'>
                Leyenda alternativa: Oficinas del CFCRL (colectiva)
              </label>
              <Field name='altCFCRLColec' value={altCFCRLColec || ''} className='form-control' />
            </div>
            <div className='col-md-4'>
              <div className='form-check form-switch'>
                <Field className='form-check-input' type='checkbox' name='showCFCRLInd' />
                <label className='form-check-label' htmlFor='showCFCRLInd'>
                  Mostrar rubro: Oficinas del CFCRL (individual)
                </label>
              </div>
            </div>
            <div className='col-md-8 mb-3'>
              <label htmlFor='altCFCRLInd' className='form-label'>
                Leyenda alternativa: Oficinas del CFCRL (individual)
              </label>
              <Field name='altCFCRLInd' value={altCFCRLInd || ''} className='form-control' />
            </div>
            <div className='col-md-4'>
              <div className='form-check form-switch'>
                <Field className='form-check-input' type='checkbox' name='showConciliacionColec' />
                <label className='form-check-label' htmlFor='showConciliacionColec'>
                  Mostrar rubro: Centros de Conciliación (colectiva)
                </label>
              </div>
            </div>
            <div className='col-md-8 mb-3'>
              <label htmlFor='altConciliacionColec' className='form-label'>
                Leyenda alternativa: Centros de Conciliación (colectiva)
              </label>
              <Field name='altConciliacionColec' value={altConciliacionColec || ''} className='form-control' />
            </div>
            <div className='col-md-4'>
              <div className='form-check form-switch'>
                <Field className='form-check-input' type='checkbox' name='showConciliacionInd' />
                <label className='form-check-label' htmlFor='showConciliacionInd'>
                  Mostrar rubro: Centros de Conciliación (individual)
                </label>
              </div>
            </div>
            <div className='col-md-8 mb-3'>
              <label htmlFor='altConciliacionInd' className='form-label'>
                Leyenda alternativa: Centros de Conciliación (individual)
              </label>
              <Field name='altConciliacionInd' value={altConciliacionInd || ''} className='form-control' />
            </div>
            <div className='col-md-4'>
              <div className='form-check form-switch'>
                <Field className='form-check-input' type='checkbox' name='showTribunalesFederalesColec' />
                <label className='form-check-label' htmlFor='showTribunalesFederalesColec'>
                  Mostrar rubro: Tribunales Federales (colectiva)
                </label>
              </div>
            </div>
            <div className='col-md-8 mb-3'>
              <label htmlFor='altTribunalesFederalesColec' className='form-label'>
                Leyenda alternativa: Tribunales Federales (colectiva)
              </label>
              <Field
                name='altTribunalesFederalesColec'
                value={altTribunalesFederalesColec || ''}
                className='form-control'
              />
            </div>
            <div className='col-md-4'>
              <div className='form-check form-switch'>
                <Field className='form-check-input' type='checkbox' name='showTribunalesFederalesInd' />
                <label className='form-check-label' htmlFor='showTribunalesFederalesInd'>
                  Mostrar rubro: Tribunales Federales (individual)
                </label>
              </div>
            </div>
            <div className='col-md-8 mb-3'>
              <label htmlFor='altTribunalesFederalesInd' className='form-label'>
                Leyenda alternativa: Tribunales Federales (individual)
              </label>
              <Field
                name='altTribunalesFederalesInd'
                value={altTribunalesFederalesInd || ''}
                className='form-control'
              />
            </div>
            <div className='col-md-4'>
              <div className='form-check form-switch'>
                <Field className='form-check-input' type='checkbox' name='showTribunalesLocalesColec' />
                <label className='form-check-label' htmlFor='showTribunalesLocalesColec'>
                  Mostrar rubro: Tribunales Locales (colectiva)
                </label>
              </div>
            </div>
            <div className='col-md-8 mb-3'>
              <label htmlFor='altTribunalesLocalesColec' className='form-label'>
                Leyenda alternativa: Tribunales Locales (colectiva)
              </label>
              <Field
                name='altTribunalesLocalesColec'
                value={altTribunalesLocalesColec || ''}
                className='form-control'
              />
            </div>
            <div className='col-md-4'>
              <div className='form-check form-switch'>
                <Field className='form-check-input' type='checkbox' name='showTribunalesLocalesInd' />
                <label className='form-check-label' htmlFor='showTribunalesLocalesInd'>
                  Mostrar rubro: Tribunales Locales (individual)
                </label>
              </div>
            </div>
            <div className='col-md-8 mb-3'>
              <label htmlFor='altTribunalesLocalesInd' className='form-label'>
                Leyenda alternativa: Tribunales Locales (individual)
              </label>
              <Field name='altTribunalesLocalesInd' value={altTribunalesLocalesInd || ''} className='form-control' />
            </div>
            <div className='col-md-4'>
              <div className='form-check form-switch'>
                <Field className='form-check-input' type='checkbox' name='showSubsidios' />
                <label className='form-check-label' htmlFor='showSubsidios'>
                  Mostrar rubro: Subsidios
                </label>
              </div>
            </div>
            <div className='col-md-8 mb-3'>
              <label htmlFor='altSubsidios' className='form-label'>
                Leyenda alternativa: Subsidios
              </label>
              <Field name='altSubsidios' value={altSubsidios || ''} className='form-control' />
              <Field name='subsidiosTLL' value={subsidiosTLL || ''} className='form-control' />
              <Field name='subsidiosCCL' value={subsidiosCCL || ''} className='form-control' />
            </div>
            <button className='col-md-4 mt-5 btn btn-success btn-block' type='submit'>
              Guardar
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default DatosGenerales;
