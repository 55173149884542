import primeraEtapa from '../../assets/1e.svg';
import segundaEtapa from '../../assets/2e.svg';
import terceraEtapa from '../../assets/3e.svg';

const InfoBasic = ({ filterStages }) => {
  return (
    <div className='row flex-column align-items-center justify-content-center' style={{ textAlign: 'justify' }}>
      <div className='col-md-6 mb-5' style={{ fontSize: 'larger', fontWeight: '500' }}>
        El Nuevo Modelo Laboral se implementa en cada una de las entidades federativas del país por etapas.
      </div>
      <div className='col-md-6 mb-5' style={{ fontSize: '500' }}>
        Da click en el mapa para encontrar la información de cada Estado.
      </div>
      <div className='col-md-6 mb-5' style={{ fontSize: '500' }}>
        Fecha de actualización: Enero 2024
      </div>
      <div className='col'>
        <div className='row mt-3 justify-content-around'>
          <div className='col-md-3 mb-2'>
            <div
              className='row flex-column align-items-center justify-content-center subtitle etapa-select text-center'
              id='primera'
              onClick={filterStages}
              style={{ fontWeight: '500' }}
            >
              <div className='col' id='primera' onClick={filterStages}>
                <img src={primeraEtapa} id='primera' onClick={filterStages} alt='Primera etapa' width='30px' />
              </div>
              <div className='col' id='primera' onClick={filterStages}>
                PRIMERA ETAPA 2020
              </div>
            </div>
          </div>
          <div className='col-md-3 mb-2'>
            <div
              className='row flex-column align-items-center justify-content-center subtitle etapa-select text-center'
              id='segunda'
              onClick={filterStages}
              style={{ fontWeight: '500' }}
            >
              <div className='col' id='segunda' onClick={filterStages}>
                <img src={segundaEtapa} id='segunda' onClick={filterStages} alt='Segunda etapa' width='30px' />
              </div>
              <div className='col' id='segunda' onClick={filterStages}>
                SEGUNDA ETAPA 2021
              </div>
            </div>
          </div>
          <div className='col-md-3 mb-2'>
            <div
              className='row flex-column align-items-center justify-content-center subtitle etapa-select text-center'
              id='tercera'
              onClick={filterStages}
              style={{ fontWeight: '500' }}
            >
              <div className='col' id='tercera' onClick={filterStages}>
                <img src={terceraEtapa} id='tercera' onClick={filterStages} alt='Tercera etapa' width='30px' />
              </div>
              <div className='col' id='tercera' onClick={filterStages}>
                TERCERA ETAPA 2022
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoBasic;
